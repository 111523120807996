import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseClassUi } from '@modules/core/base';
import { IApiResponseCallback } from '@modules/core/interfaces';
import { EmailContactUs } from '../../models';
import { ContactUsService } from '../../services';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent extends BaseClassUi implements OnInit, IApiResponseCallback<any>{
  public contactUsFormGroup: FormGroup;
  emailContactUs: EmailContactUs = new EmailContactUs();
  submitted: boolean = false;
  constructor(public contactUsService: ContactUsService) { super() }


  ngOnInit(): void {
    init(this);
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.contactUsFormGroup);

    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  handleResponse(response: any) {
    this.dataTransferService.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }
}
function init(context: ContactUsComponent) {
  context.title = 'Get in Touch with SignitiveAi Ltd for Expert IT Solutions | Contact Us';
  context.description = 'Contact SignitiveAi for innovative technology solutions and consulting services. Get in touch with us for your IT product development, modernization, and digital transformation needs.';
  context.keywords = 'Contact Information, Customer Support, Contact Form, Reach Out, Communication Channels, Contact Us, Customer Service, Inquiry, Support Desk, Contact Details';
  context.seoTags();

  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);

}

function addFormValidation(context: ContactUsComponent) {
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, [Validators.required]),
    lastName: new FormControl(context.emailContactUs.lastName),
    // phoneNumber: new FormControl(context.emailContactUs.phoneNumber, [Validators.required]),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message, Validators.required),
  });
}


// function init(context: ProductxComponent) {
//   context.title = 'SignitiveAi ProductX Page | Let the Data Drive your Business';
//   context.description = 'Page description here...';
//   context.keywords = 'Page keywords here...';
//   context.seoTags();
// }