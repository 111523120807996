import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b22223',
  templateUrl: './blog-details-b22223.component.html',
  styleUrls: ['./blog-details-b22223.component.css']
})
export class BlogDetailsB22223Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB22223Component) {  
  context.title = 'Applications of Edge Computing in Businesses | SignitiveAi';
  context.description = 'Explore how edge computing is being used in businesses to improve speed, accuracy, and performance for IoT, video analytics, AR and VR, retail analytics, and predictive maintenance applications';
  context.keywords = 'Edge Computing, Real-Time Data Processing, Latency Reduction, Internet of Things (IoT), Data Analysis, Video Analytics, Traffic Monitoring, Retail Analytics, Predictive Maintenance, Augmented Reality (AR), Virtual Reality (VR), Data Localisation, Device Data Processing, Low-Latency Connections, Real-Time Decision Making, Security Cameras, Inventory Management, Personalised Marketing, Manufacturing Efficiency, Cost Savings';
  context.seoTags();
}

