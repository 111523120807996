import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b15323',
  templateUrl: './blog-details-b15323.component.html',
  styleUrls: ['./blog-details-b15323.component.css']
})
export class BlogDetailsB15323Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB15323Component) {  
  context.title = 'The Difference Between Digitisation, Digitalisation, and Digital Transformation';
  context.description = "Learn the distinct processes of digitisation, digitalisation, and digital transformation and understand how these processes can help businesses remain competitive in today's digital landscape.";
  context.keywords = 'Digitisation, Digitalisation, Digital Transformation, Analogue to Digital Conversion, Data Management, Electronic Files, Digital Tools, Automation, Efficiency, Decision-Making, Customer Relationship Management (CRM), Supply Chain Management, Online Marketing, Business Operations, Digital Products, Digital Services, Competitiveness, Customer Satisfaction, Innovation, Business Models';
  context.seoTags();
}
