import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b191022',
  templateUrl: './blog-details-b191022.component.html',
  styleUrls: ['./blog-details-b191022.component.css']
})
export class BlogDetailsB191022Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB191022Component) {  
  context.title = 'Design Thinking Process in IT Product Development: A User-Centered Approach';
  context.description = 'Discover how design thinking can be a valuable tool in IT product development by prioritizing end-user needs. Learn the five-step process - empathize, define, ideate, prototype, and test - to create innovative solutions that meet user needs';
  context.keywords = 'Design Thinking, Empathy, User-Centered Design, Problem Solving, End-User Needs, Research, Observation, User Interviews, Define the Problem, Problem Statement, Ideation, Creativity, Innovation, Prototyping, Wireframes, Functional Prototypes, Testing with Users, Feedback Loop, Iterative Process, Collaboration';
  context.seoTags();
}

