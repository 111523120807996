import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-sr-java-developer',
  templateUrl: './sr-java-developer.component.html',
  styleUrls: ['./sr-java-developer.component.css']
})
export class SrJavaDeveloperComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: SrJavaDeveloperComponent) {  
  context.title = 'Java Developer - SignitiveAi';
  context.description = 'We are looking for a Java Developer with 4-6 years of core development experience.';
  context.keywords = 'Java Development, Spring Framework (Spring Boot, Spring MVC), Hibernate ORM, Microservices Architecture, RESTful APIs, JPA (Java Persistence API), Java 8+ Features (Streams, Lambdas), Multithreading and Concurrency, Apache Kafka, Kubernetes and Docker, Maven/Gradle Build Tools, Unit Testing (JUnit, TestNG), CI/CD Pipelines, SQL and NoSQL Databases, Java EE (Enterprise Edition)';
  context.seoTags();
}
