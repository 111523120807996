import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-four',
  templateUrl: './productx-sub-page-four.component.html',
  styleUrls: ['./productx-sub-page-four.component.css']
})
export class ProductxSubPageFourComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}

function init(context: ProductxSubPageFourComponent) {
  context.title = 'Simplify Your Document Workflow with Advanced Document Management Solutions';
  context.description = 'Transform your document management with our advanced Document Catalogue. Streamline workflows, enhance data accuracy, and ensure compliance with effortless data capture, validation, and enrichment. Elevate your operations with next-gen document solutions that offer seamless document handling and up-to-date vocabulary management.';
  context.keywords = 'Intelligent Automation, Machine Learning Integration, Predictive Analytics, Process Optimization, Robotic Process Automation (RPA), Natural Language Processing (NLP), Decision Intelligence, Cognitive Computing, Real-Time Data Insights, Workflow Automation, AI-Powered Innovation, Autonomous Systems, Adaptive Algorithms, Smart Process Management, Digital Transformation';
  context.seoTags();
  }
