import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b4123',
  templateUrl: './blog-details-b4123.component.html',
  styleUrls: ['./blog-details-b4123.component.css']
})
export class BlogDetailsB4123Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB4123Component) {  
  context.title = 'AI-Powered Compliance Systems: Benefits for Businesses | SignitiveAi';
  context.description = 'Learn how AI-powered compliance systems help businesses navigate complex regulations and laws, improve accuracy, increase efficiency, and enable proactive risk management';
  context.keywords = 'AI-Powered Compliance, Machine Learning, Natural Language Processing, Regulatory Compliance, Compliance Monitoring, Risk Management, Proactive Risk Mitigation, Automation, Compliance Reporting, Data Analysis, Real-time Monitoring, Accuracy Improvement, Compliance Automation, Customizable Solutions, Industry Regulations, Compliance Issues, Compliance Tools, Efficiency in Compliance, Regulatory Reporting, Compliance Risk Management';
  context.seoTags();
}

