import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b4522',
  templateUrl: './blog-details-b4522.component.html',
  styleUrls: ['./blog-details-b4522.component.css']
})
export class BlogDetailsB4522Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB4522Component) {  
  context.title = 'Latest Trends in Frontend Technologies in Software Development';
  context.description = 'Stay up to date with the latest frontend technologies in software development. Learn about progressive web applications, single page applications, serverless architecture, micro frontends, low-code development, and web components';
  context.keywords = 'Progressive Web Applications (PWAs), Native Mobile Apps, Offline Functionality, Push Notifications, Single Page Applications (SPAs), Dynamic Page Updates, React, Angular, Vue.js, Serverless Architecture, Cloud Computing, Functions-as-a-Service (FaaS), AWS Lambda, Azure Functions, Micro Frontends, Modular Architecture, Low-Code Development, Drag-and-Drop Interfaces, Web Components, Custom Elements';
  context.seoTags();
}


