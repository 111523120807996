import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-mortgage-solution',
  templateUrl: './mortgage-solution.component.html',
  styleUrls: ['./mortgage-solution.component.css']
})
export class MortgageSolutionComponent extends BaseClassUi {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MortgageSolutionComponent) {
  context.title = 'Mortgage Management Solutions: Streamline Lending with Advanced Automation and Compliance';
  context.description = 'Optimize your mortgage operations with our comprehensive suite of solutions, including digital mortgage applications, automated underwriting, and robust compliance management. Enhance customer engagement with our CRM system, streamline document handling, and efficiently manage loan servicing and payments. Safeguard your operations with proactive risk management and advanced analytics for informed decision-making.';
  context.keywords = 'Digital Mortgage Applications, Automated Underwriting, Regulatory Compliance, Mortgage Processes, Mortgage Efficiency, Mortgage Compliance, Mortgage CRM Solutions, Mortgage DMS, Loan Management, Payments System, Mortgage Operations, Mortgage Risk Management, Mortgage Applications, Swift Loan Approvals, TILA compliant, ECOA compliant, Compliance management (system), borrower information Verifications, borrower Verifications, integrated financial institutions, Mortgage CRM system, Document Management, Easy access (easy document access), Loan Management, accounts Management, payment system (processing), Market Risks, Risk Assessment Models, Stress Testing, Scenario Analysis, Portfolio Performance, Borrower Behavior, Market Trends, Data Analytics (tools)';
  context.seoTags();
}
