import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-quality-engineer',
  templateUrl: './quality-engineer.component.html',
  styleUrls: ['./quality-engineer.component.css']
})
export class QualityEngineerComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: QualityEngineerComponent) {
  context.title = 'Quality Engineer Vacancy at SignitiveAi - Redefining Tech & Business Services';
  context.description = `We are looking for experienced QA professional with 5-9 years' expertise in diverse testing methodologies, automation, Agile environments, database proficiency, andCI/CD tools for efficient quality assurance as a Quality Engineer.`;
  context.keywords = 'Quality Engineer Services, Quality Assurance Engineer, Quality Engineering Solutions, Software Quality Engineer, Quality Control Engineering, QA/QC Engineer, Quality Assurance Testing, Product Quality Engineer, Reliability Engineering, Process Improvement Engineer, Automation Testing Engineer, Engineering Quality Standards, Quality Management System (QMS), Quality Engineer Certification, Root Cause Analysis Engineer';
  context.seoTags();
}
