import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-trainee-data-analyst',
  templateUrl: './trainee-data-analyst.component.html',
  styleUrls: ['./trainee-data-analyst.component.css']
})
export class TraineeDataAnalystComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: TraineeDataAnalystComponent) {  
  context.title = 'Trainee Data Analyst (Consulting Projects Division) - SignitiveAi';
  context.description = 'SignitiveAi is a business consulting firm based in London offering research services to a diverse range of clients around the world.';
  context.keywords = 'Data Collection, Data Cleaning, Data Visualization, SQL Queries, Excel for Data Analysis, Statistical Analysis, Data Interpretation, Reporting Dashboards, Data Mining, Data Modeling, Trend Analysis, Python for Data Analysis, R Programming, Machine Learning Basics, Data Warehousing';
  context.seoTags();
}
