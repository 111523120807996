import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsComponent) {  
  context.title = 'Blogs - SignitiveAi';
  context.description = 'Latest Trends in Business Digital Transformation, Fintech Industry & Software Development';
  context.keywords = 'Blog Content, Content Creation, Blogging Tips, Blog Post, SEO for Blogs, Blog Writing, Audience Engagement, Blog Marketing, WordPress Blog, Content Strategy';
  context.seoTags();
}
