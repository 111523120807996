import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-ui-engineers-reactjs-nodejs',
  templateUrl: './ui-engineers-reactjs-nodejs.component.html',
  styleUrls: ['./ui-engineers-reactjs-nodejs.component.css']
})
export class UiEngineersReactjsNodejsComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: UiEngineersReactjsNodejsComponent) {  
  context.title = 'UI Engineers - ReactJS, NodeJS Vacancy at SignitiveAi - Redefining Tech & Business Services';
  context.description = `We are looking for UI Engineers to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'ReactJS Development, NodeJS Development, Front-End Engineering, Back-End Integration, JavaScript Frameworks, Single Page Applications (SPA), Component-Based Architecture, JSX Syntax, React Hooks, State Management (Redux, Context API), NodeJS API Development, Server-Side Rendering (SSR), RESTful APIs, Full-Stack Development, Asynchronous Programming';
  context.seoTags();
}

