import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b21922',
  templateUrl: './blog-details-b21922.component.html',
  styleUrls: ['./blog-details-b21922.component.css']
})
export class BlogDetailsB21922Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB21922Component) {  
  context.title = 'Latest Trends in Architecture Design for IT Product Development ';
  context.description = 'Explore the latest trends in architecture design for IT product development, including cloud-native, microservices, event-driven, serverless, and data-driven architectures. Learn how these trends can help businesses create efficient and resilient applications';
  context.keywords = 'Architecture Design, IT Product Development, Cloud-Native Architecture, Scalability, Flexibility, Cost Savings, Microservices Architecture, Independent Components, Application Deployment, Event-Driven Architecture, System Events, Serverless Architecture, Infrastructure Management, Cost Efficiency, Data-Driven Architecture, Data-Informed Decisions, Resilience, Performance Optimisation, Application Design, Business Agility';
  context.seoTags();
}

