import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: WhoWeAreComponent) {  
  context.title = 'About SignitiveAi Ltd - Innovative Solutions for Business Digital Transformation and Customized Technology | Expert IT Product Engineering Services';
  context.description = 'Learn more about SignitiveAi - a UK-based software development company specializing in custom software development, mobile app development, web development, and digital transformation. Contact us today to discuss your project';
  context.keywords = 'Company Overview, Our Mission, Corporate Identity, Company Values, About Us, Organizational Culture, Vision Statement, Company History, Brand Identity, Corporate Philosophy';
  context.seoTags();
}