import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b18123',
  templateUrl: './blog-details-b18123.component.html',
  styleUrls: ['./blog-details-b18123.component.css']
})
export class BlogDetailsB18123Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB18123Component) {  
  context.title = 'Strategies in Business Process Improvement: How to Improve Your Operations';
  context.description = 'Discover key strategies for improving your business processes and achieving organizational goals with business process improvement. Learn how to define, analyse, set improvement goals, develop improvement strategies, implement changes, monitor, and measure results, and continuously improve.';
  context.keywords = 'Business Process Improvement (BPI), Process Efficiency, Process Effectiveness, Goal Setting (SMART), Process Analysis, Bottlenecks, Process Mapping, Value Stream Mapping, Process Redesign, Automation, Technology Implementation, Continuous Improvement, Key Performance Indicators (KPIs), Improvement Strategies, Change Management, Process Monitoring, Process Optimization, Organizational Goals, Employee Training, Performance Measurement';
  context.seoTags();
}

