import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b29323',
  templateUrl: './blog-details-b29323.component.html',
  styleUrls: ['./blog-details-b29323.component.css']
})
export class BlogDetailsB29323Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB29323Component) {  
  context.title = 'Why Your Business Needs a Digital Transformation Strategy | SignitiveAi';
  context.description = 'Read our latest blog post on why your business needs a digital transformation strategy and how SignitiveAi can help you achieve it. Contact us to learn more';
  context.keywords = 'Digital Transformation, Artificial Intelligence (AI), Machine Learning, Internet of Things (IoT), Cloud Computing, Cybersecurity, Digital Talent Management, Customer Experience, Automation, Predictive Analytics, Smart Sensors, Real-Time Data, Scalability, Data Security, Decision-Making, Personalization, Digital Innovation, Workforce Upskilling, Operational Efficiency, Competitive Edge';
  context.seoTags();
}

