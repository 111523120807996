import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-java-developer',
  templateUrl: './java-developer.component.html',
  styleUrls: ['./java-developer.component.css']
})
export class JavaDeveloperComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: JavaDeveloperComponent) {  
  context.title = 'Java Developer - SignitiveAi';
  context.description = 'We are looking for a Java Developer with 2-3 years of core development experience.';
  context.keywords = 'Java Programming, Java SE (Standard Edition), Spring Framework, Hibernate ORM, RESTful Web Services, Java Persistence API (JPA), Object-Oriented Programming (OOP), JDBC (Java Database Connectivity), Maven/Gradle Build Tools, Multithreading, Java 8+ Features (Streams, Lambdas), JavaFX (UI Development), JUnit Testing, SQL and NoSQL Databases, Microservices Architecture';
  context.seoTags();
}
