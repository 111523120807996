import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-three',
  templateUrl: './merfx-sub-page-three.component.html',
  styleUrls: ['./merfx-sub-page-three.component.css']
})
export class MerfxSubPageThreeComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}


function init(context: MerfxSubPageThreeComponent) {
  context.title = 'Expedite Contract Approval with Our Underwriting Process Solution';
  context.description = 'Accelerate contract creation and approval with our advanced Underwriting Process solution. Ensure compliance, streamline sales processes, and simplify deposit verification. Efficiently manage customer lists and expedite workflows for faster, more accurate approvals.';
  context.keywords = 'Contract Automation, E-Signatures, Digital Approvals, Workflow Automation, Document Routing, Approval Workflow, Contract Lifecycle Management (CLM), Approval Tracking, Real-Time Collaboration, Compliance Checks, Review and Revision Management, Multi-Party Approvals, Version Control, Conditional Approvals, Time-to-Approval Metrics';
  context.seoTags();
}
