import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b14823',
  templateUrl: './blog-details-b14823.component.html',
  styleUrls: ['./blog-details-b14823.component.css']
})
export class BlogDetailsB14823Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB14823Component) {
  context.title = "The Digital Immune System: Revolutionizing Cybersecurity in the Digital Age";
  context.description = "Discover how the Digital Immune System (DIS) is transforming cybersecurity by providing a comprehensive, resilient approach to protect digital ecosystems. Learn about threat detection, incident response, continuous monitoring, and the key building blocks of an effective DIS to safeguard your organisation's data and operations.";
  context.keywords = 'Digital Immune System, Cybersecurity Resilience, Threat Detection, Threat Intelligence, Incident Response, Continuous Monitoring, Perimeter Security, Endpoint Security, Identity and Access Management (IAM), Security Information and Event Management (SIEM), Cybersecurity Awareness, Real-Time Surveillance, Data Breaches, Robust Security Controls, Collaboration in Cybersecurity, Regular Security Audits, Customer Experience (CX), System Downtime Prevention, Vulnerability Management, Resilient Digital Ecosystems';
  context.seoTags();
}

