import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx',
  templateUrl: './productx.component.html',
  styleUrls: ['./productx.component.css']
})
export class ProductxComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductxComponent) {
  context.title = 'Revolutionize Your Operations with AI-Powered Solutions for Workflow and Document Management';
  context.description = 'Discover how our intelligent solutions can revolutionize your business operations. Harness AI to optimize processes, ensure data transparency, and streamline document management. Our platform offers efficient document workflows, seamless workflow customization, and comprehensive reporting, all designed to enhance efficiency and compliance. Elevate your business with intuitive interfaces and advanced security features.';
  context.keywords = 'Data Lineage, Process Mining, Enterprise Content Management (ECM), Document Catalogue, Content Management, Document Management, E-Signature Tool, Workflow Software, CRM Specific Workflow Software, Role Specific Workflow Software, Document Workflow Software, Various Workflow Software, Apps workflow Software, Task workflow software, Document Workflow, Optimize Workflows, Data Journey, AI Processes, Workflow Customization, Interactive Visualization, Comprehensive Reporting, Intuitive Interface, Customizable Workflows, Global Document Ontology, Enhanced Security';
  context.seoTags();
}
