import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-product-owner-uk',
  templateUrl: './product-owner-uk.component.html',
  styleUrls: ['./product-owner-uk.component.css']
})
export class ProductOwnerUkComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductOwnerUkComponent) {  
  context.title = 'Product Owner (UK) Vacancy at SignitiveAi - Redefining Tech & Business Services';
  context.description = `We are recruiting passionate Product Owners to join our expanding team in London. You will work with business leaders to turn software visions into reality, guiding designers and engineers to deliver exceptional user experiences, processes, and technologies.`;
  context.keywords = 'Product Roadmap, Agile Methodologies, Scrum Framework, Backlog Management, Stakeholder Engagement, User Story Creation, Product Vision, Feature Prioritization, Business Analysis, MVP Development (Minimum Viable Product), Cross-Functional Collaboration, Customer Feedback Integration, Sprint Planning, Product Lifecycle Management, Data-Driven Decision Making';
  context.seoTags();
}

