import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-three',
  templateUrl: './productx-sub-page-three.component.html',
  styleUrls: ['./productx-sub-page-three.component.css']
})
export class ProductxSubPageThreeComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductxSubPageThreeComponent) {
  context.title = 'Transform Your Operations with AI-Powered Process Optimization';
  context.description = 'Elevate your business with AI-driven process revolution. Streamline workflows, unlock insights, and receive personalized recommendations for unparalleled efficiency and innovation.';
  context.keywords = 'Data Traceability, Version Control, Data Lineage, Audit Trails, Change Tracking, Data Integrity, Metadata Management, Real-Time Updates, Data Transparency, Compliance Monitoring, Data Governance, Ethical Data Practices, Open Data Standards, Data Collaboration, Historical Data Analysis';
  context.seoTags();
  }
