import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClassUi } from '@modules/core/base';
import { IApiResponseCallback } from '@modules/core/interfaces';
import { EmailContactUs } from '@modules/features/modules/contact/models';
import { ContactUsService } from '@modules/features/modules/contact/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseClassUi implements OnInit, IApiResponseCallback<any> {
  public contactUsFormGroup: FormGroup;
  submitted: boolean = false;
  emailContactUs: EmailContactUs = new EmailContactUs();
  constructor(public contactUsService: ContactUsService) {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

  onSubmit(event:any) {
    event.preventDefault();
    this.submitted = true;
    console.log(this.contactUsFormGroup);

    if (this.contactUsFormGroup.valid) {
      this.contactUsService.submit(this);
    }
  }

  handleResponse(response: any) {
    this.dataTransferService.shareData('contactUs');
    this.showPopupModal();
    this.contactUsFormGroup.reset();
    this.submitted = false;
  }

  // onCognitoServiceClick() {
  //   this.commonFunctions.navigateWithoutReplaceUrl(Paths.PATH_COGNITIVE_MINING);
  // }
  // onLineageServiceClick() {
  //   this.commonFunctions.navigateWithoutReplaceUrl(Paths.PATH_CROSS_APP_LINEAGE);
  // }
  // onAutomationServiceClick() {
  //   this.commonFunctions.navigateWithoutReplaceUrl(Paths.INTELLIGENT_PROCESS_AUTOMATION);
  // }

}

function init(context: HomeComponent) {
  context.title = 'SignitiveAi Ltd - Digital Transformation Consulting & Custom Software Development Services';
  context.description = 'SignitiveAi Ltd specializes in digital transformation consulting, custom software development, and technology consulting services. We offer services in business process automation, cloud migration, software development outsourcing, IT modernization, and more.';
  context.keywords = 'Auto Car Loan Collection, Auto Car Loan Underwriting, Auto Car Loan UW Automation, Auto Car Loan Reporting & Analytics, Auto Car Loan Customer Journey Automation, Auto Car Loan Customer Management, Auto Car Loan Garage, Auto Car Loan Purchase, Auto Car Loan Sales, Auto Car Loan Incident Management, Data Lineage, Process Mining, Enterprise Content Management (ECM), Document Catalogue, Content Management, Document Management, E-Signature Tool, Workflow Software, CRM Specific Workflow Software, Role Specific Workflow Software, Digital Mortgage Applications, Automated Underwriting, Regulatory Compliance, Mortgage Processes, Mortgage Efficiency, Mortgage Compliance, Mortgage CRM Solutions, Mortgage DMS, Loan Management, Payments System';
  context.seoTags();
  addFormValidation(context);
  context.contactUsService.setformGroup(context.contactUsFormGroup);
}


function addFormValidation(context: HomeComponent) {
  context.contactUsFormGroup = new FormGroup({
    firstName: new FormControl(context.emailContactUs.firstName, [Validators.required]),
    lastName: new FormControl(context.emailContactUs.lastName),
    // phoneNumber: new FormControl(context.emailContactUs.phoneNumber, [Validators.required]),
    email: new FormControl(context.emailContactUs.toAddress, [Validators.required, Validators.email]),
    message: new FormControl(context.emailContactUs.message, Validators.required),
  });
}
