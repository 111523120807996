import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b8623',
  templateUrl: './blog-details-b8623.component.html',
  styleUrls: ['./blog-details-b8623.component.css']
})
export class BlogDetailsB8623Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB8623Component) {  
  context.title = 'Bridging the Technological Divide: Enhancing Digital Maturity in UK Adult Social Care';
  context.description = 'Explore the state of digital maturity in UK adult social care, identifying key challenges such as limited technology access and low digital record adoption. Learn how targeted strategies and investments can bridge the technological gap, enhancing care quality and operational efficiency.';
  context.keywords = 'Digital Maturity, Adult Social Care, Digital Divide, Technology Adoption, Infrastructure Investment, Broadband Connectivity, Digital Social Care Records, Operational Efficiency, Training and Upskilling, Digital Transformation, Internet Connectivity, Digital Tools, Patient Care Improvement, Capacity Building, Collaboration Strategies, Data Protection, Record-Keeping Automation, Best Practices Sharing, Technological Readiness, Stakeholder Engagement';
  context.seoTags();
}

