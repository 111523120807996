import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

  function init(context: CareerComponent) {  
    context.title = 'Career Opportunities at SignitiveAi Ltd for Innovative IT Experts | Join Us';
    context.description = 'Looking to join a team of experts in technology consulting, product engineering, and digital transformation? SignitiveAi is hiring! Find out more.';
    context.keywords = 'Career Opportunities, Job Openings, Professional Growth, Career Development, Employment, Career Path, Job Satisfaction, Talent Acquisition, Work-Life Balance, Skill Development';
    context.seoTags();
  }
