import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-dot-net-developer',
  templateUrl: './dot-net-developer.component.html',
  styleUrls: ['./dot-net-developer.component.css']
})
export class DotNetDeveloperComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: DotNetDeveloperComponent) {  
  context.title = '.NET Developer - SignitiveAi';
  context.description = 'A technical professional with hands-on experience writing quality code and understanding business in Fintech and retails industry.';
  context.keywords = '.NET Framework, ASP.NET Core, C# Programming, Entity Framework, MVC Architecture, Web API Development, LINQ (Language Integrated Query), SQL Server, Azure Cloud Services, Dependency Injection, Razor Pages, Windows Presentation Foundation (WPF), Blazor Development, Object-Oriented Programming (OOP), Microservices Architecture';
  context.seoTags();
}
