import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-one',
  templateUrl: './productx-sub-page-one.component.html',
  styleUrls: ['./productx-sub-page-one.component.css']
})
export class ProductxSubPageOneComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductxSubPageOneComponent) {
  context.title = 'Optimize Your Business Processes with Innovative Workflow Management Solutions';
  context.description = 'Elevate your workflow management with our cutting-edge Workflow Vault. Seamlessly define, adapt, and optimize workflows to boost efficiency and eliminate errors. Stay ahead of the competition and drive your business forward with our state-of-the-art solution.';
  context.keywords = 'Document Automation, Workflow Integration, Document Routing, Electronic Signatures (E-Signatures), Version Control, Approval Workflow, Document Collaboration, Audit Trails, Metadata Management, File Sharing Solutions, Template Management, Real-Time Document Tracking, Access Control, Compliance Management, Document Lifecycle';
  context.seoTags();
  }
