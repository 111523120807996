import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-blog-details-b28922',
  templateUrl: './blog-details-b28922.component.html',
  styleUrls: ['./blog-details-b28922.component.css']
})
export class BlogDetailsB28922Component extends BaseClassUi {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogDetailsB28922Component) {  
  context.title = 'The Latest Trends in Cloud Modernisation Strategies| SignitiveAi';
  context.description = 'Discover the latest trends in cloud modernisation strategies, including multi-cloud adoption, serverless computing, containerization, microservices architecture, and AI and machine learning. Learn how these trends can help your business improve its agility, reduce costs, and optimise its operations';
  context.keywords = 'Cloud Modernisation, Multi-Cloud Adoption, Vendor Lock-In, Scalability, Agility, Cost Efficiency, Serverless Computing, Infrastructure Management, Containerization, Flexibility, Portability, Microservices Architecture, Application Development, Cloud Providers, Resilience, AI and Machine Learning, Automation, Operational Optimisation, Cloud Infrastructure, Cloud Strategy';
  context.seoTags();
}

