import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx',
  templateUrl: './merfx.component.html',
  styleUrls: ['./merfx.component.css']
})
export class MerfxComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxComponent) {
  context.title = 'Transform Car Finance Operations: Streamline, Secure, and Optimize with Product-X';
  context.description = 'Discover how Product-X revolutionizes car finance operations with a unified platform for enhanced efficiency and security. Manage data across departments, track vehicle and customer journeys, and ensure error-free processes from acquisition to contract creation. Features include repossession management, lead tracking, advanced reporting, and a dedicated customer portal. Streamline operations, safeguard data privacy, and drive business growth with customizable solutions.';
  context.keywords = 'Auto Car Loan Collection, Auto Car Loan Underwriting, Auto Car Loan UW Automation, Auto Car Loan Reporting & Analytics, Auto Car Loan Customer Journey Automation, Auto Car Loan Customer Management, Auto Car Loan Garage, Auto Car Loan Purchase, Auto Car Loan Sales, Auto Car Loan Incident Management, Auto Car  Loan Enterprise Integration, Auto Car Loan IT Service Management, Auto loan calculator, Car loan rates, auto loan underwriting, car loan approval process, Auto Loan Automation, Car Loan Underwriting Software, Auto Loan Analytics, Car Loan Reporting Tools, auto loan customer journey, car loan automation, Auto loan management, Car loan servicing, Auto repair services, Car garage near me, Car purchase financing, Auto loan application, Used Car Sales, Car Dealership Near Me, Auto loan incident resolution, Car loan management system, auto loan integration, enterprise system integration, auto loan management software, IT service management for car loans';
  context.seoTags();
}



