import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-dot-net-technical-architect',
  templateUrl: './dot-net-technical-architect.component.html',
  styleUrls: ['./dot-net-technical-architect.component.css']
})
export class DotNetTechnicalArchitectComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: DotNetTechnicalArchitectComponent) {  
  context.title = '.NET Technical Architect - SignitiveAi';
  context.description = 'We are looking for a dot Net Architect who can understand the customer business in Fintech and Retail industry and provide a scalable business solution that can add value to the business.';
  context.keywords = '.NET Architecture, Solution Design, Microservices Architecture, Cloud Solutions (Azure, AWS), ASP.NET Core, Entity Framework, C# Programming, Distributed Systems, Design Patterns, Domain-Driven Design (DDD), RESTful APIs, Web Services (SOAP, REST), Performance Optimization, Database Design (SQL Server, NoSQL), Continuous Integration/Continuous Deployment (CI/CD)';
  context.seoTags();
}
