export class Paths {
    public static PATH_HOME = "home";
    public static PATH_BLANK = "";

    public static PATH_PRODUCTX_SUB_PAGE_ONE = "simplify-your-document-workflow";
    public static PATH_PRODUCTX_SUB_PAGE_TWO = "workflow-optimization";
    public static PATH_PRODUCTX_SUB_PAGE_THREE = "transparent-data-evolution";
    public static PATH_PRODUCTX_SUB_PAGE_FOUR = "simplify-your-document-workflow";
    public static PATH_WHO_WE_ARE = "who-we-are";
    public static PATH_OUR_VALUES = "our-values";
    public static PATH_ESG = "esg";   
    public static ABOUT_US = "about-us";
    public static PATH_CONTACT = "contact-us";
    public static THANK_YOU = "thank-you";
    public static PATH_PRIVACY_POLICY = "privacy-policy";
    public static PATH_TERMS_OF_USE = "terms-of-use";
    public static PATH_COOKIES_POLICY = "cookies-policy";
   
    public static PATH_MERFX = "merfx";
    public static PATH_PRODUCTX = "productx";

    // public static PATH_CUSTOMER_HANDLING = "mortgage-solution";
    public static PATH_MORTGAGE_SOLUTION = "mortgage-solution";


    public static PATH_MERFX_ONE = "simplify-post-contract-activities";
    public static PATH_MERFX_TWO = "gain-actionable-insights";
    public static PATH_MERFX_THREE = "expedite-contract-approval";
    public static PATH_MERFX_FOUR = "optimize-procurement-processes";
    public static PATH_MERFX_FIVE = "maximize-operational-efficiency";
    public static PATH_MERFX_SIX = "elevate-customer-engagement";
    public static PATH_MERFX_SEVEN = "optimize-vehicle-management";

    public static PATH_BLOGS = "blogs";
    public static PATH_BLOG_DETAILS1 = "blog-details-30823";
    public static PATH_BLOG_DETAILS2 = "blog-details-14823";
    public static PATH_BLOG_DETAILS3 = "blog-details-4823";
    public static PATH_BLOG_DETAILS4 = "blog-details-12723";
    public static PATH_BLOG_DETAILS5 = "blog-details-19623";
    public static PATH_BLOG_DETAILS6 = "blog-details-8623";
    public static PATH_BLOG_DETAILS7 = "blog-details-17523";
    public static PATH_BLOG_DETAILS8 = "blog-details-5523";
    public static PATH_BLOG_DETAILS9 = "blog-details-19423";
    public static PATH_BLOG_DETAILS10 = "blog-details-29323";
    public static PATH_BLOG_DETAILS11 = "blog-details-22323";
    public static PATH_BLOG_DETAILS12 = "blog-details-15323";
    public static PATH_BLOG_DETAILS13 = "blog-details-8323";
    public static PATH_BLOG_DETAILS14 = "blog-details-1323";
    public static PATH_BLOG_DETAILS15 = "blog-details-22223";
    public static PATH_BLOG_DETAILS16 = "blog-details-15223";
    public static PATH_BLOG_DETAILS17 = "blog-details-8223";
    public static PATH_BLOG_DETAILS18 = "blog-details-1223";
    public static PATH_BLOG_DETAILS19 = "blog-details-25123";
    public static PATH_BLOG_DETAILS20 = "blog-details-18123";
    public static PATH_BLOG_DETAILS21 = "blog-details-11123";
    public static PATH_BLOG_DETAILS22 = "blog-details-4123";
    public static PATH_BLOG_DETAILS23 = "blog-details-141222";
    public static PATH_BLOG_DETAILS24 = "blog-details-91222";
    public static PATH_BLOG_DETAILS25 = "blog-details-71222";
    public static PATH_BLOG_DETAILS26 = "blog-details-301122";
    public static PATH_BLOG_DETAILS27 = "blog-details-161122";
    public static PATH_BLOG_DETAILS28 = "blog-details-21122";
    public static PATH_BLOG_DETAILS29 = "blog-details-261022";
    public static PATH_BLOG_DETAILS30 = "blog-details-191022";
    public static PATH_BLOG_DETAILS31 = "blog-details-51022";
    public static PATH_BLOG_DETAILS32 = "blog-details-28922";
    public static PATH_BLOG_DETAILS33 = "blog-details-21922";
    public static PATH_BLOG_DETAILS34 = "blog-details-7922";
    public static PATH_BLOG_DETAILS35 = "blog-details-17822";
    public static PATH_BLOG_DETAILS36 = "blog-details-10822";
    public static PATH_BLOG_DETAILS37 = "blog-details-3822";
    public static PATH_BLOG_DETAILS38 = "blog-details-20722";
    public static PATH_BLOG_DETAILS39 = "blog-details-6722";
    public static PATH_BLOG_DETAILS40 = "blog-details-15622";
    public static PATH_BLOG_DETAILS41 = "blog-details-1622";
    public static PATH_BLOG_DETAILS42 = "blog-details-26522";
    public static PATH_BLOG_DETAILS43 = "blog-details-19522";
    public static PATH_BLOG_DETAILS44 = "blog-details-15522";
    public static PATH_BLOG_DETAILS45 = "blog-details-12522";
    public static PATH_BLOG_DETAILS46 = "blog-details-8522";
    public static PATH_BLOG_DETAILS47 = "blog-details-4522";
    public static PATH_BLOG_DETAILS48 = "blog-details-28422";
    public static PATH_BLOG_DETAILS49 = "blog-details-24422";


    public static PATH_CAREER = "career";
    public static ALL_JOB_OPENINGS = "all-job-openings";

    public static PATH_QUALITY_ENGINEER = "quality-engineer";
    public static PATH_GOLANG_SOFTWARE_ENGINEER = "golang-software-engineer";
    public static PATH_UI_ENGINEERS_REACTJS_NODEJS = "ui-engineers-reactjs-nodejs";
    public static PATH_SR_ENGINEER_DEVOPS = "sr-engineer-devops";
    public static PATH_PRODUCT_OWNER_UK = "product-owner-uk";
    public static BIG_DATA_CONSULTANT = "big-data-consultant";
    public static PATH_DOT_NET_DEVELOPER = "dot-net-developer";
    public static PATH_SR_JAVA_DEVELOPER = "sr-java-developer";
    public static PATH_JAVA_DEVELOPER = "java-developer";
    public static PATH_CHAOS_RESILIENCY_TEST_ENGINEER = "chaos-resiliency-test-engineer";
    public static PATH_BUSINESS_ANALYST = "business-analyst";
    public static PATH_DOT_NET_TECHNICAL_ARCHITECT = "dot-net-technical-architect";
    public static PATH_TRAINEE_PROJECT_MANAGER = "trainee-project-manager";
    public static PATH_TRAINEE_DATA_ANALYST = "trainee-data-analyst";
    public static PATH_CUSTOMER_SERVICE_ASSOCIATE = "customer-service-associate";
    public static PATH_E_LEARNING_DEVELOPER = "e-learning-developer"; 
    
    public static PATH_NOT_FOUND = "**";

    // { path: '**', component: NotFoundComponent },
}
