import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-four',
  templateUrl: './merfx-sub-page-four.component.html',
  styleUrls: ['./merfx-sub-page-four.component.css']
})
export class MerfxSubPageFourComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageFourComponent) {
  context.title = 'Optimize Procurement Processes with Our Purchase Management Solution';
  context.description = 'Enhance inventory control and optimize costs with our Purchase Management solution. Streamline asset acquisition, automate procurement workflows, and centralize cost management for improved financial control. Drive operational efficiency and achieve better financial management through optimized purchase processes.';
  context.keywords = 'Procurement Automation, Supplier Management, E-Procurement, Purchase Order Management, Contract Lifecycle Management (CLM), Sourcing Optimization, Vendor Relationship Management, Spend Analysis, Inventory Control, Supplier Performance Evaluation, Strategic Sourcing, Procurement Analytics, Electronic Invoicing, Compliance Tracking, Purchase Requisition Management';
  context.seoTags();
}
