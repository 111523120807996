import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-golang-software-engineer',
  templateUrl: './golang-software-engineer.component.html',
  styleUrls: ['./golang-software-engineer.component.css']
})
export class GolangSoftwareEngineerComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: GolangSoftwareEngineerComponent) {  
  context.title = 'Golang Software Engineer Vacancy at SignitiveAi - Redefining Tech & Business Services';
  context.description = `We are looking for Golang Developer to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.`;
  context.keywords = 'Golang Developer, Golang Software Engineer, Go Programming Language, Go Developer Services, Golang Backend Developer, Golang Application Development, Golang Software Solutions, Go Lang Engineering, Go Developer Expertise, Golang Microservices, Golang Cloud Development, Golang Web Development, Golang API Development, Golang Performance Optimization, Go Developer Hiring';
  context.seoTags();
}
