import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-trainee-project-manager',
  templateUrl: './trainee-project-manager.component.html',
  styleUrls: ['./trainee-project-manager.component.css']
})
export class TraineeProjectManagerComponent extends BaseClassUi {

  constructor() {
    super();
  }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: TraineeProjectManagerComponent) {  
  context.title = 'Trainee Project Manager (Intern) - SignitiveAi';
  context.description = 'SignitiveAi is a business consulting firm based in London offering research services to a diverse range of clients around the world.';
  context.keywords = 'Project Planning, Task Scheduling, Resource Allocation, Stakeholder Communication, Project Scope, Risk Management, Gantt Charts, Agile Methodology, Scrum Framework, Project Budgeting, Project Milestones, Team Collaboration, Time Management, Quality Control, Project Documentation';
  context.seoTags();
}